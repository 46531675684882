<template>
  <RegisterAttendee />
</template>

<script>
import RegisterAttendee from "@/components/join/RegisterAttendee";

export default {
  name: "RegisterAttendeePage",
  props: {},
  components: {
    RegisterAttendee,
  },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
